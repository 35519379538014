.banner_wrapper{
    max-height: 90vh;
    min-height: 90vh;
    border-radius: 15px;
    background-color: rgb(240, 240, 240);
}

.banner_content{
    padding: 5%;
    position: relative;
    top: 45vh;
}

.loginButton{
    background-color: #1C3F63;
    width: 100%;
    transition: all 0.3s;
}

.loginButton:hover{
    background-color: rgb(2, 1, 8);
}

.loginForm{
    padding-top: 22vh;
}